import React from 'react';
import { StoreContext } from "../../context/StoreContext"
import styled from "styled-components";

const LineItemMobile = props => {
    const { line_item } = props

    const {
      removeLineItem,
      checkout,
    } = React.useContext(StoreContext)

    const imageItem = line_item.variant.image ? (
        <figure className="image">
            <img
                src={line_item.variant.image.src}
                alt={line_item.variant.image.altText}
            />
        </figure>
    ) : null;

    const removeItem = (e) => {
      const lineToDelete = e.target.closest('.item');
      lineToDelete.classList.add('opaque');
      removeLineItem(checkout.id, line_item.id)
    }

    return (
      <StyledMobileLineItem>
        <hr/>
        <div className="item">
          <div className="image">{imageItem}</div>


          <div className="item-info">
            <div className="row top">
              <div className="item-desc">
                <p className="item-title">{line_item.title}</p>
                {line_item.variant.title !== 'Default Title' && 
                  (<p className="product-desc">{line_item.variant.title}</p>)
                }
              </div>
              <p className="close-icon delete"  onClick={(e)=>removeItem(e)}>Delete</p>
            </div>

            <div className="row bottom">
              <p className="item-quantity"><span>Amount</span><span>{line_item.quantity}</span></p>
              <p className="item-price"><span>Price</span><span>{`$${(line_item.variant.price.amount * 1).toFixed(2)}`}</span></p>
              <p className="item-total"><span>Total</span><span>{`$${(line_item.quantity * line_item.variant.price.amount).toFixed(2)}`}</span></p>
            </div>

          </div>


        </div>
    </StyledMobileLineItem>
    );
};

export default LineItemMobile;

const StyledMobileLineItem = styled.div`

&:first-of-type {
  hr {
    margin: 0 0 10px 0;

  }
}

hr {
  margin: 10px 0;
}



.item {
  display: flex;
  transition: 0.2s opacity ease-out;

    &.opaque {
      opacity: 0.1;
    }


  .image {
    padding-right: 10px;
    box-sizing: content-box;
    width: 80px;
  }

  .item-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .close-icon {
      margin-left: 10px;
    }

    .item-variant {
      padding-top: 4px;
    }
  }

  .row {
    flex-direction: row;
    display: flex;

    .item-title {
      max-width: 22ch;
    }

    &.top, &.bottom {
      justify-content: space-between;
    }

    &.bottom {
      padding-top: 15px;

      p {
        display: flex;
        flex-direction: column;

        > span:first-of-type {
          font-size: 10px;
        }
      }
    }
  }
}


.imageItem {
  flex-grow: 0;
}

.top-row {
  padding-right: 10px;
}
`;