import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as vars from "../../variables.js";
import MobileMenuButton from "./mobileMenuButton";
import MobileMenu from "./mobileMenu";
import HeartCart from "../heartCart";

const MobileNav = ({path}) => {
  const [pageLoaded, setLoaded] = useState(false);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(()=>{
      setLoaded(true)
    }, 500);
  }, []);

  return (
    <StyledMobileNav>
      <div className={pageLoaded ? "mobile-nav-wrapper loaded" : "mobile-nav-wrapper"}>
        <HeartCart></HeartCart>
        <MobileMenuButton open={open} setOpen={setOpen} />
        <MobileMenu path={path} open={open} setOpen={setOpen} />
      </div>
    </StyledMobileNav>
  );
};

export default MobileNav;

const StyledMobileNav = styled.div`
  display: none;

  @media only screen and (max-width: ${vars.mobile}) {
    display: block;
  }

  .mobile-nav-wrapper {
    position: fixed;
    top: 0;
    top: 0;
    left: 0;

    width: 100%;
    max-width: 100vw;
    height: 50px;
    opacity: 0;
    transition: .2s opacity ease-out;
    pointer-events: none;
    &.loaded {
      opacity: 1;
    }
  }


  .mobile-filter-bar {
    display: none;
    height: 30px;
    left: 0;
    background-color: white;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    width: 100%;
    z-index: -1;
    top: 50px;
    position: fixed;
    height: 30px;
    align-items: center;


    

    @media only screen and (max-width: ${vars.mobile}) {

      &.visible {
        display: flex;
      }
    }
  }

`;
