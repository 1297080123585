import * as React from "react"
import { StoreContext } from "../../context/StoreContext";

const LineItem = props => {
    const { line_item } = props;

    const {
        removeLineItem,
        checkout,
      } = React.useContext(StoreContext)

  
    const imageItem = line_item.variant.image ? (
        <figure className="image is-96x96">
            <img
                src={line_item.variant.image.src}
                alt={line_item.variant.image.altText ? line_item.variant.image.altText : "nothing here" }
            />
        </figure>
    ) : null;

    const removeItem = (e) => {
      const lineToDelete = e.target.closest('tr');
      lineToDelete.classList.add('opaque');
      removeLineItem(checkout.id, line_item.id)
    }

    return (
        <tr>
            <th >
                <div className="columns">
                    <hr/>
                    <div className="column">
                        {imageItem}
                    </div>
                    <div className="column">
                      <p className="product-title">{line_item.title}</p>

                      {line_item.variant.title !== 'Default Title' && 
                        (<p className="product-desc">{line_item.variant.title}</p>)
                      }
                    </div>

                </div>
            </th>
            <th align="center">
                {line_item.quantity}
            </th>
            <th >
                {`$${(line_item.variant.price.amount * 1).toFixed(2)}`}
            </th>
            <th>
                {`$${(line_item.quantity * line_item.variant.price.amount).toFixed(2)}`}
            </th>
            <th>
                <p className="has-text-weight-normal delete" onClick={(e)=>removeItem(e)}>Delete</p>
            </th>
        </tr>
    );
};

export default LineItem;
